<template>
  <div class="specialtyDetail">
    <div class="title">{{ specialtyDetail.specialtyName }}</div>
    <div class="smalltitle">
      <div class="titleline"></div>
      <div class="titletext">专业概览</div>
    </div>
    <div class="smallcontent">
      <div>学历层次：{{ specialtyDetail.level == 1 ? "本科" : "专科" }}</div>
      <div>毕业年限：{{ specialtyDetail.years }}年</div>
      <div>文理比例：{{ specialtyDetail.artsRatio }}</div>
      <div>男女比例：{{ specialtyDetail.sexRatio }}</div>
      <div>授予学位：{{ specialtyDetail.degree }}</div>
    </div>
    <div class="content">
      {{ specialtyDetail.introduce }}
    </div>
    <div class="smalltitle">
      <div class="titleline"></div>
      <div class="titletext">主要课程</div>
    </div>
    <div class="content">
      {{ specialtyDetail.courses }}
    </div>
    <div class="smalltitle">
      <div class="titleline"></div>
      <div class="titletext">考研方向</div>
    </div>
    <div class="content">
      {{ specialtyDetail.postgraduate }}
    </div>
    <div class="smalltitle">
      <div class="titleline"></div>
      <div class="titletext">就业方向</div>
    </div>
    <div class="content">
      {{ specialtyDetail.mainEmployment }}
    </div>
    <div class="content">
      <div>主要职业去向</div>
      <div class="line"></div>
      <div>
        {{ specialtyDetail.mainOccupation }}({{
          specialtyDetail.mainOccupationRate
        }}%)
      </div>
    </div>
    <div class="content">
      <div>毕业平均薪资</div>
      <div class="line"></div>
      <div>{{ specialtyDetail.averageSalary }}元</div>
    </div>
    <div class="content">
      <div>主要行业去向</div>
      <div class="line"></div>
      <div>
        {{ specialtyDetail.mainIndustry }}({{
          specialtyDetail.mainIndustryRate
        }}%)
      </div>
    </div>
    <div class="img">
      <img :src="QRcode[0]" alt="" style="width: 150px" />
    </div>
    <div class="imgtitle">
        更多信息请使用微信扫一扫前往OK志愿查看
    </div>
  </div>
</template>

<script>
import { getSpecialtyDetail } from "@/api/dataquery";

export default {
  data() {
    return {
      specialtyDetail: {}, //专业详情
      QRcode:[],
    };
  },
  mounted() {
    let specialtyId = this.$route.query.specialtyId;
    this.getSpecialtyDetail(specialtyId);
    this.getQRcode()
  },
  methods: {
    // 小程序图片
    async getQRcode() {
      let res = await getAdvertisementDetail({ id: 8 });
      if (res.code == 1) {
        this.QRcode = res.data.images || [];
      }
    },
    getSpecialtyDetail(specialtyId) {
      getSpecialtyDetail({ specialtyId: specialtyId }).then((res) => {
        if (res.code == 1) {
          this.specialtyDetail = res.data || {};
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.specialtyDetail {
  .title {
    height: 40px;
    line-height: 40px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .smalltitle {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0px;
    .titleline {
      width: 8px;
      height: 26px;
      background: #1ab394;
      border-radius: 0px 4px 4px 0px;
    }
    .titletext {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      height: 26px;
      line-height: 26px;
      margin-left: 10px;
    }
  }
  .smallcontent {
    display: flex;
    margin-left: 18px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    height: 20px;
    line-height: 20px;
    div {
      margin-right: 30px;
    }
  }
  .content {
    display: flex;
    margin-left: 18px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    .line {
      margin-top: 12px;
      flex: auto;
      border-top: 1px dashed #e6e6e6;
      overflow: hidden;
    }
  }
  .imgtitle {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }
  .img {
      width: 150px;
      margin: 0px auto;
    }
}
</style>
